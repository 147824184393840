import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

const Contact = ({ data }) => {
    const url = data.site.siteMetadata.siteUrl;
    const description = `Pour vos projets de construction de piscine et d'aménagement paysager dans le Var et les départements voisins.`;

    return(
        <Layout headerStyle="darkHeader" url={url}>
            <Seo title="Contactez-nous" description={description}/>
            <ContactFormSection/>
            <ContactInfoSection/>
        </Layout>
    )
}

export default Contact;

export const query = graphql`
query PageContactQuery {
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`