import React, { useState } from 'react'
import { navigate } from 'gatsby';
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import Button from '../shared/button'
import Form, { Input, Textarea, FormGroup } from '../shared/form'
import { FormWrap } from './contact-form.stc'

function encode(data) {
	return Object.keys(data)
	  .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
	  .join('&');
  }

const ContactForm = ({ inputStyle, textareaStyle, url }) => {
	const { register, handleSubmit, formState: { errors } } = useForm({
		mode: "onBlur"
	})
	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	});
	const [value, setValue] = useState({
		name: '',
		email: '',
		subject: '',
		message: ''
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
			setValue({
				name: '',
				email: '',
				subject: '',
				message: ''
			})
		}
	};

	const onSubmit = (data, e) => {
		const form = e.target;
		
		// Prevent the default onSubmit behavior
		e.preventDefault();
		// console.log('contact : ', data);
		// POST the encoded form with the content-type header that's required for a text submission
		// Note that the header will be different for POSTing a file
		fetch('/', {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		  body: encode({
			'form-name': "contact",
			...data,
		  }),
		})
		  // On success, redirect to the custom success page using Gatsby's `navigate` helper function
		  .then(() => handleServerResponse(true, "Merci de nous avoir contacté!", form))
		  .then(() => setServerState({ submitting: true }))
		  .then(() => navigate('/merci'))
		  // On error, show the error in an alert
		  .catch(err => handleServerResponse(false, err.response.data.error, form))

	}

	const onChangeHandler = e => {
		setValue({ ...value, [e.target.name]: e.target.value })
	}
	
	return (
		<FormWrap>
			<Form onSubmit={handleSubmit(onSubmit)} action="/" data-netlify="true" data-netlify-honeypot="bot-field" name="contact" method="post">
			<input type="hidden" name="form-name" value="contact" />
			<input type="hidden" name="bot-field"/>
				<div className="form-row">
					<FormGroup className="halfwidth">
						<Input
							type="text"
							name="name"
							id="name"
							placeholder="Votre nom"
							onChange={onChangeHandler}
							{...register('name', { required: true })}
							{...inputStyle}
						/>
						{errors.name?.type === 'required' && <span className="error">Vous devez nous indiquer votre nom</span>}
						{/* <input {...register("firstName", { required: true })} />
      					{errors.firstName?.type === 'required' && "First name is required"} */}
					</FormGroup>
					<FormGroup className="halfwidth">
						<Input
							type="email"
							name="email"
							id="email"
							placeholder="Votre e-mail"
							onChange={onChangeHandler}
							{...register('email',{
								required: true,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "adresse e-mail invalide"
								}
							})}
						/>
						{errors.email?.type === 'required' && <span className="error">Votre e-mail est requit</span>}
					</FormGroup>
				</div>
				<div className="form-row">
					<FormGroup {...textareaStyle}>
						<Textarea
							name="message"
							placeholder="Votre demande"
							onChange={onChangeHandler}
							{...register('message',{
								required: true,
								minLength: { value: 10, message: "Un message de taille minimum est demandé" }
							})}
						/>
						{errors.message?.type === 'required' && <span className="error">Votre message est nécessaire...</span>}
					</FormGroup>
				</div>
				<div className="form-row">
					<FormGroup>
						<Button type="submit" disabled={serverState.submitting}>Envoyer</Button>
						{serverState.status && (
							<p className={`form-output ${!serverState.status.ok ? "En raison d'un problème technique, votre message n'a pas été envoyé. veuillez nous en excuser" : "Message envoyé!"}`}>
								{serverState.status.msg}
							</p>
						)}
					</FormGroup>
				</div>
			</Form>
		</FormWrap>
	)
}

ContactForm.propTypes = {
	inputStyle: PropTypes.object,
	textareaStyle: PropTypes.object
}

ContactForm.defaultProps = {
	inputStyle: {
		responsive: {
			xsmall: {
				mb: '20px'
			}
		}
	},
	textareaStyle: {
		mt: '40px',
		mb: '40px',
		responsive: {
			xsmall: {
				mt: '25px',
				mb: '25px'
			}
		}
	}
}

export default ContactForm
