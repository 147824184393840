import React from 'react';
import { TileLayer, Marker, Popup, MapContainer } from 'react-leaflet'

const LeafletMap = () => {

const position = [43.405079261604136, 6.080159484426203];
        
  return (
    <MapContainer center={position} zoom={15} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          Jardipiscines - faite de votre rêve une réalité!
        </Popup>
      </Marker>
    </MapContainer>
  )
}
export default LeafletMap;